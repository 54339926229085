.main {
    padding: 0 64px 64px;
  }
  
  .card1 {
    border-radius: 16px;
    margin: 0 auto;
    width: 500px;
    max-width: 100%;
    min-height: 400px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 5px 8px 0px rgba(0, 0, 0, 0.14),
      0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    background-image: url("../assets/images/BPA.jpg");
    background-size: cover;
  }
  
  .info1 {
    position: relative;
    width: 100%;
    height: 500px;
    background-color:#263173;
    transform: translateY(100%)
      translateY(-88px)
      translateZ(0);
    transition: transform 0.5s ease-out;
  }
  
  .info1:before {
    z-index: -1;
    display: block;
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: url("../assets/images/BPA.jpg");
    filter: blur(10px);
    background-size: cover;
    opacity: 0.25;
    transform: translateY(-100%)
      translateY(88px)
      translateZ(0);
    transition: transform 0.5s ease-out;
  }
  
  .card1:hover .info1,
  .card1:hover .info1:before {
    transform: translateY(0) translateZ(0);
  }

  .card2 {
    border-radius: 16px;
    margin: 0 auto;
    width: 500px;
    max-width: 100%;
    min-height: 400px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 5px 8px 0px rgba(0, 0, 0, 0.14),
      0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    background-image: url("../assets/images/Solution\ Architecture.jpg");
    background-size: cover;
  }
  
  .info2 {
    position: relative;
    width: 100%;
    height: 500px;
    background-color:#263173;
    transform: translateY(100%)
      translateY(-88px)
      translateZ(0);
    transition: transform 0.5s ease-out;
  }
  
  .info2:before {
    z-index: -1;
    display: block;
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: url("../assets/images/Solution\ Architecture.jpg");
    filter: blur(10px);
    background-size: cover;
    opacity: 0.25;
    transform: translateY(-100%)
      translateY(88px)
      translateZ(0);
    transition: transform 0.5s ease-out;
  }
  
  .card2:hover .info2,
  .card2:hover .info2:before {
    transform: translateY(0) translateZ(0);
  }
  .card3 {
    border-radius: 16px;
    margin: 0 auto;
    width: 500px;
    max-width: 100%;
    min-height: 400px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 5px 8px 0px rgba(0, 0, 0, 0.14),
      0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    background-image: url("../assets/images/IT\ technical\ support.jpg");
    background-size: cover;
  }
  
  .info3 {
    position: relative;
    width: 100%;
    height: 500px;
    background-color:#263173;
    transform: translateY(100%)
      translateY(-88px)
      translateZ(0);
    transition: transform 0.5s ease-out;
  }
  
  .info3:before {
    z-index: -1;
    display: block;
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image:url("../assets/images/IT\ technical\ support.jpg");
    filter: blur(10px);
    background-size: cover;
    opacity: 0.25;
    transform: translateY(-100%)
      translateY(88px)
      translateZ(0);
    transition: transform 0.5s ease-out;
  }
  
  .card3:hover .info3,
  .card3:hover .info3:before {
    transform: translateY(0) translateZ(0);
  }
  .card4 {
    border-radius: 16px;
    margin: 0 auto;
    width: 500px;
    max-width: 100%;
    min-height: 400px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 5px 8px 0px rgba(0, 0, 0, 0.14),
      0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    background-image: url("../assets/images/Bigdata.jpg");
    background-size: cover;
  }
  
  .info4 {
    position: relative;
    width: 100%;
    height: 500px;
    background-color:#263173;
    transform: translateY(100%)
      translateY(-88px)
      translateZ(0);
    transition: transform 0.5s ease-out;
  }
  
  .info4:before {
    z-index: -1;
    display: block;
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: url("../assets/images/Bigdata.jpg");
    filter: blur(10px);
    background-size: cover;
    opacity: 0.25;
    transform: translateY(-100%)
      translateY(88px)
      translateZ(0);
    transition: transform 0.5s ease-out;
  }
  
  .card4:hover .info4,
  .card4:hover .info4:before {
    transform: translateY(0) translateZ(0);
  }
  .card5 {
    border-radius: 16px;
    margin: 0 auto;
    width: 500px;
    max-width: 100%;
    min-height: 400px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 5px 8px 0px rgba(0, 0, 0, 0.14),
      0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    background-image: url("../assets/images/Java.jpg");
    background-size: cover;
  }
  
  .info5 {
    position: relative;
    width: 100%;
    height: 500px;
    background-color:#263173;
    transform: translateY(100%)
      translateY(-88px)
      translateZ(0);
    transition: transform 0.5s ease-out;
  }
  
  .info5:before {
    z-index: -1;
    display: block;
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: url("../assets/images/Java.jpg");
    filter: blur(10px);
    background-size: cover;
    opacity: 0.25;
    transform: translateY(-100%)
      translateY(88px)
      translateZ(0);
    transition: transform 0.5s ease-out;
  }
  
  .card5:hover .info5,
  .card5:hover .info5:before {
    transform: translateY(0) translateZ(0);
  }
  .card6 {
    border-radius: 16px;
    margin: 0 auto;
    width: 500px;   
    max-width: 100%;
    min-height: 400px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 5px 8px 0px rgba(0, 0, 0, 0.14),
      0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    background-image: url("../assets/images/IT.jpg");
    background-size: cover;
  }
  
  .info6 {
    position: relative;
    width: 100%;
    height: 500px;
    background-color:#263173;
    transform: translateY(100%)
      translateY(-88px)
      translateZ(0);
    transition: transform 0.5s ease-out;
  }
  
  .info6:before {
    z-index: -1;
    display: block;
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: url("../assets/images/IT.jpg");
    filter: blur(10px);
    background-size: cover;
    opacity: 0.25;
    transform: translateY(-100%)
      translateY(88px)
      translateZ(0);
    transition: transform 0.5s ease-out;
  }
  
  .card6:hover .info6,
  .card6:hover .info6:before {
    transform: translateY(0) translateZ(0);
  }

  
  .title1 {
    margin: 0;
    padding: 24px;
    font-size: 30px;
    line-height: 1;
    color: #f2c230;
  }
  
  .description1 {
    margin-top: 50px ;
    padding: 0 24px 24px;
    font-size: 15px;
    line-height: 1.5;
    color: #f2c230;
  }
  
  /* General layout and typography stuff */
  @import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400");
  
  * {
    box-sizing: border-box;
    position: relative;
    transition: all .3s ease
  }
  
  .html {
    font-size: 16px
  }
  
  .body {
    font-family: Open Sans, Verdana, sans-serif;
    color: rgba(0, 0, 0, .87);
    font-weight: 400;
    line-height: 1.45
  }
  
  body,
  header {
    background: #fafafa
  }
  
  .header {
    padding: 40px;
    min-height: 200px;
    text-align: center;
    color: rgba(0, 0, 0, .87)
  }
  
  header > * {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto
  }
  
  header>:last-child {
    margin-bottom: 0
  }
  
  .h4 {
    margin-bottom: 0.5em;
    font-weight: inherit;
    line-height: 1.2;
    color: #1c5b72;
    font-size: 25px;
  }
  
  @media (min-width:800px) {
    .h4 {
      font-size: 25px;
      font-weight: 800;
    }
  }
  
  .p {
    margin-bottom: 1.3em;
    line-height: 1.618
  }
  
  @media (min-width:800px) {
    .p {
      font-size: 0.5em
    }
  }
  
  .a {
    color: #e03616;
    text-decoration: none
  }
  