.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 500px;
    height: 600px;
    margin: auto;
    border-radius: 25px;
    animation: borderAnimation 3s linear infinite alternate;
    border: 5px solid #f2c230;
    text-align: justify ;
  }
  @keyframes borderAnimation {
    0% {
        border-color:#f2c230 /* Initial border color */
    }
    50% {
      border-color:greenyellow;
    }
    100% {
        border-color: #263173; /* Final border color */
    }
}
.img-ct{
    min-height: 200px;
}
.para{
  padding: 2 10px 10px;
  font-size: 15px;
  line-height: 1.5;
  color: #000;
}