
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color:  #0088d1  ;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #03295c;
  border-top-color: #e2eefd;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #106eea;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #3284f1;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}
/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
  background:#F2C230;
  height: 40px;
  font-size: 14px;
  transition: all 0.5s;
  color: #263173;
  padding: 0;
}

#topbar .contact-info i {
  font-style: normal;
  color: #263173;
}

#topbar .contact-info i a,
#topbar .contact-info i span {
  padding-left: 5px;
  color: #263173;
}

#topbar .contact-info i a {
  line-height: 0;
  transition: 0.3s;
  transition: 0.3s;
}

#topbar .contact-info i a:hover {
  color: #fff;
  text-decoration: underline;
}

#topbar .social-links a {
  color: #263173;
  line-height: 0;
  transition: 0.3s;
  margin-left: 20px;
}

#topbar .social-links a:hover {
  color: white;
}
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  background-color: #263173;
  transition: all 0.5s;
  z-index: 997;
  height: 86px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header.fixed-top {
  height: 70px;
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: "Poppins", sans-serif;
}

#header .logo a {
  color: #222222;
}

#header .logo a span {
  color: #F2C230;
}

#header .logo img {
  max-height: 40px;
}

.scrolled-offset {
  margin-top: 70px;
}

        /*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 30px;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar>ul>li {
  white-space: nowrap;
  padding:  0px 0px  10px 28px;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px;
  font-size: 15px;
  font-weight: 600;
  /* color: #ff6806; */
  white-space: nowrap;
  transition: 0.3s;
  position: relative;
  text-decoration: none;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar>ul>li>a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color:#F2C230;
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}

.navbar a:hover:before,
.navbar li:hover>a:before,
.navbar .active:before {
  visibility: visible;
  width: 100%;
 
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #F2C230;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 28px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-weight: 400;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #F2C230;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.navbar-light .navbar-toggler {
  color: #fff;

}
.mobile-nav-toggle {
  color: #f2c230;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #f2c230;
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  left: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #f2c230;
}

.navbar-mobile>ul>li {
  padding: 0;
}

.navbar-mobile a:hover:before,
.navbar-mobile li:hover>a:before,
.navbar-mobile .active:before {
  visibility: hidden;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #fff;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #106eea;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}
 /*logo*/
 .FaxianIT-logo{
    height: 70px;
    width :120px;
 }
/*--------------------------------------------------------------
# home Section
--------------------------------------------------------------*/
#home {
  width: 100%;
  height: 70vh;
  background-color:#263173;
  position: relative;
}

#home:before {
  content: "";
  /*background: rgba(255, 255, 255, 0.6);*/
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#home .container {
  position: relative;
}

#home h1 {
  margin: 0;
  font-size: 40px;
  font-weight: 800;
  line-height: 56px;
  color: #fff;
  font-family: "Poppins",sans-serif;
}

#home h1 span {
  color: #106eea;
}

#home h2 {
  color: #fff;
  margin: 5px 0 30px 0;
  font-size: 16px;
  font-weight: 400;
}

#home .btn-get-started {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background:#F2C230;
  text-decoration: none;
  font-weight: bold;
}

#home .btn-get-started:hover {
  background: #263173;
}

#home .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  color: #222222;
  font-weight: 600;
  display: flex;
  align-items: center;
}

#home .btn-watch-video i {
  color: #106eea;
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

#home .btn-watch-video:hover {
  color: #106eea;
}

#home .btn-watch-video:hover i {
  color: #3b8af2;
}

@media (min-width: 1024px) {
  #home {
    background-attachment: fixed;
  }
}

/* @media (max-width: 768px) {
  #home {
    height: 100vh;
  } */
  
  @media (max-width: 768px){
#home {
    height: 80vh;
}


  #home h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #home h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  #home .btn-get-started,
  #home .btn-watch-video {
    font-size: 13px;
  }
}

@media (max-height: 500px) {
  #home {
    height: 120vh;
  }
}

.banner-img2{
  height:250px;
}
.banner-img3{
  height:400px;
}
@media only screen and (max-width: 600px) {
  .banner-img2 {
    height: 150px !important;
}
}
  
 /*banner rightside image*/
 .banner-img2{
    height:350px;
 }
.who{
    font-weight: 700;
    color:#F2C230;
    font-size:16px;
}
.signin-text{
    font-size: 24px;
    font-weight: 700;
}
.title{
    font-size: 14px;
    font-weight:bold;
    text-decoration: none;
    color:#263173;
    
}
.titlefront{
  font-weight:bold;
  text-decoration: none;
  color:#000;
  text-align: center; 
    font-size: 24px;
}
.about-icon{
    color:#f2c230;
    font-size: 30px;
}
.services-card{
    padding: 30px;
    border-radius:12px !important;
    /* height: 100%; Set a fixed height for the cards / */
  display: flex;
  flex-direction: column;
   
}
.ser-head{
    font-size: 40px;
    font-weight: 700;
    color: #263173;
    font-family: "Poppins",sans-serif;
}
.icon {
    width: 64px;
    height: 64px;
    background: #f1f6fe;
    border-radius: 4px;
    border: 1px solid #deebfd;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: ease-in-out 0.3s;
}
p.description {
    color: #000;
}
.cyber-about-img img {
    border-radius: 10px;
}
.sheild-img img {
    border: 20px solid #fff;
    border-radius: 10px;
    margin-top: -140px;
    margin-left: -30px;
}
.cyber-about-count-box {
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,.25);
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,.25);
    border-left: 10px solid rgb(255 185 63);
    border-radius: 10px;
}
@media (min-width: 768px) {
  .d-md-flex {
      display: flex!important;
  }
}
.scale-up-hor-center {
	-webkit-animation: scale-up-hor-center 5s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite both;
	        animation: scale-up-hor-center 5s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite both;
}

 @-webkit-keyframes scale-up-hor-center {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
@keyframes scale-up-hor-center {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}


/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  border-bottom: 5px solid rgb(255 185 63);;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.3);
  padding: 20px 0 30px 0;
  border-radius: 30px;
  height:300px;
}

.contact .info-box i {
  font-size: 32px;
  color: #f2c230;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  padding: 8px;
  border: 2px dotted #263173;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #263173;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.4);
  padding: 30px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #f0cf24;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form .form-group {
  margin-bottom: 20px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #106eea;
}

.contact .php-email-form input {
  padding: 10px 15px;
  border-radius: 30px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
  border-radius:30px;
}

.contact .php-email-form button[type=submit] {
  background: #106eea;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #3b8af2;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
ul {
  margin: 0px;
  padding: 0px;
}
.footer-section {
background:  #263173;
position: relative;
}
.footer-cta {
border-bottom:1px solid #e8e7e7;
}
.single-cta i {
color: #ff5e14;
font-size: 30px;
float: left;
margin-top: 8px;
}
.cta-text {
padding-left: 15px;
display: inline-block;
}
.cta-text h4 {
color: #fff;
font-size: 20px;
font-weight: 600;
margin-bottom: 2px;
}
.cta-text span {
color: #e8e7e7;
font-size: 15px;
}
.footer-content {
position: relative;
z-index: 2;
}
.footer-pattern img {
position: absolute;
top: 0;
left: 0;
height: 330px;
background-size: cover;
background-position: 100% 100%;
}
.footer-logo {
margin-bottom: 30px;
}
.footer-logo img {
  max-width: 200px;
}
.footer-text p {
margin-bottom: 14px;
font-size: 14px;
    color: #e8e7e7;
line-height: 28px;
}
.footer-social-icon span {
color: #fff;
display: block;
font-size: 20px;
font-weight: 700;
font-family: 'Poppins', sans-serif;
margin-bottom: 20px;
}
.footer-social-icon a {
color: #fff;
font-size: 16px;
margin-right: 15px;
}
.footer-social-icon i {
height: 40px;
width: 40px;
text-align: center;
line-height: 38px;
border-radius: 50%;
}
.facebook-bg{
background: #3B5998;
}
.twitter-bg{
background: #55ACEE;
}
.google-bg{
background: #DD4B39;
}
.footer-widget-heading h3 {
color: #fff;
font-size: 20px;
font-weight: 600;
margin-bottom: 40px;
position: relative;
}
.footer-widget-heading h3::before {
content: "";
position: absolute;
left: 0;
bottom: -15px;
height: 2px;
width: 50px;
background: #F2C230;
}
.footer-widget ul li {
display: inline-block;
float: none;
width: 50%;
margin-bottom: 12px;
}
.footer-widget ul li a:hover{
color: #F2C230;
}
.footer-widget ul li a {
color: #e8e7e7;
text-transform: capitalize;
}
.subscribe-form {
position: relative;
overflow: hidden;
}
.subscribe-form input {
width: 100%;
padding: 14px 28px;
background: #2E2E2E;
border: 1px solid #2E2E2E;
color: #fff;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: #ff5e14;
  padding: 13px 20px;
  border: 1px solid #ff5e14;
  top: 0;
}
.subscribe-form button i {
color: #fff;
font-size: 22px;
transform: rotate(-6deg);
}
.copyright-area{
background: #263173;
padding: 25px 0;
}
.copyright-text p {
margin: 0;
font-size: 14px;
color: #ffffff;
}
.copyright-text p a{
color:#263173;
}
.footer-menu li {
display: inline-block;
margin-left: 20px;
}
.footer-menu li:hover a{
color: #ff5e14;
}
.footer-menu li a {
font-size: 14px;
color: #878787;
}

/* / Location icon / */

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px; 
}


.map-icon-3d {
  font-size: 3em; 
  transform-style: preserve-3d;
  animation: spin 4s linear infinite;
}

@keyframes spin {
  0%, 100% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
}

/* / Envelope icon / */
@keyframes envelope {
  50% { content: '\f2b6'; }
}

.fa-animation-envelope:before {
  animation-duration: 2s;
  animation-iteration-count: infinite;  
}

.fa-animation-envelope:before {
  animation-name: envelope;
}

/* / phone icon / */



.pulse {
  position: relative;
  width: 40px;
  height: 40px; 
  background: white; 
  border-radius: 50%;
  margin: 0 auto;
}

.pulse .fa{
  color: #0074d9; 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) ;
  font-size: 1.5em;
}

.pulse:before,
.pulse:after {
  content: '';
  display: block;
  position: absolute;
  border: 50%;
  border: 1px solid #0074d9; 
  top: -8px;
  left: -8px; 
  right: -8px; 
  bottom: -8px; 
  border-radius: 50%;
  animation: animatephone 1.5s linear infinite;
  opacity: 0;
}

.pulse:after {
  animation-delay: 0.5s;
}

@keyframes animatephone {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

/*ERP*/
.servicesbanner-heading{
  font-size: 70px;
  font-weight: 800;
  line-height: 56px;
  color: #fff;
  padding-top: 120px;
  font-family: "Poppins",sans-serif;
  }
  
  .erp-img{
  height: 350px;
  }
  .ser-head{
    
  font-weight: 800;
  }
  .ser-para{
  color: 
  #a3a2a2;
  }
  .list-two-col li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 12px;
  }

  #services-bg {
    width: 100%;
    height: 50vh;
    background-color:#111827;
    background-image: url('assets/images/page-header-bg.svg');
    }

    /* / Flipcard / */
    .card-3d .card-container {
      width: 100%;
      height: 100%;
      transition: transform 0.5s;
      transform-style: preserve-3d;
      backface-visibility: hidden;
      position: relative;
    }
    
    .card-3d .card-front,
    .card-3d .card-back {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      backface-visibility: hidden;
    }
    
    
    .card-3d .card-back {
      /* / Back side styles / */
      transform: rotateY(180deg);
    }
    
    .card-3d.flipped .card-container {
      transform: rotateY(180deg);
    }
    
    .title1{
      font-size: 13px;
      text-decoration: none;
      color: #000;
  }
  .title2{
    font-size: 13px;
    color: #2e2d2d;
}
.btn-get-started {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #106eea;
  text-decoration: none;
  font-weight: bold;
}

/* / Button css / */

.pushable {
  position: relative;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}
.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  transform: translateY(2px);
}
.edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 7px;
  background: #106eea;
}
.front1 {
  display: block;
  position: relative;
  padding: 10px 28px;
  border-radius: 4px;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  color: white;
  background: #106eea;
  transform: translateY(-4px);
}

.pushable:hover .front {
  transform: translateY(-6px);
}
.pushable:hover .shadow {
  transform: translateY(4px);
}
.pushable:active .front {
  transform: translateY(-2px);
}
.pushable:active .shadow {
  transform: translateY(1px);
}

.pushable:focus:not(:focus-visible) {
  outline: none;
}
@media (min-width: 280px) and (max-width: 653px) {
  .icon-box {
    height: 650px !important;
  }
}
@media (min-width: 600px) and (max-width: 1024px) {
  .icon-box {
    height: 550px !important;
  }
}

.icon-box {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height:410px;
}


 .d-flex1 {
  display: flex;
}

.justify-content-center1 {
  justify-content: center;
  margin-top: 35px;
}

.align-items-center1 {
  align-items: center;
}

.liststylesmanage{
  list-style: none;
  font-size: 14px;
}
.fa-close{
  color:#fd7101;
  margin-right: 10px;
}
.required{
  background-color: #03295c;
  color:#fff;
  border-left: 5px solid #fd7101;
}
.fa-check{
  color:#fd7101;
}
.client{
  color:#263173;

}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

.button1 {
  border-radius: 4px;
  background: #ffffff;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  padding: 10px;
  width: 150px;
  height: 50px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 4px;
 
}

.button1 span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button1 span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button1:hover span {
  padding-right: 25px;
}

.button1:hover span:after {
  opacity: 1;
  right: 0;
}
.button-container {
  margin-top: 40px; /* Adjust margin as needed */
}

a.nav-link.scrollto.navhome {

  white-space: nowrap;
    padding: 10px 0 10px 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3px;
    font-size: 17px;
    font-weight: 800;
    color:#f2c230;
    white-space: nowrap;
    transition: 0.3s;
    position: relative;
    text-decoration: none;
    background-color: #263173;
}

a.nav-link.scrollto.navhome:hover{
 color:#f2c230;
  border-bottom: 3px solid  #f2c230;
}

a.nav-link.scrollto.active.navhome {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3px;
    font-size: 17px;
    font-weight: 800;
    color: #f2c230;
    white-space: nowrap;
    transition: 0.3s;
    position: relative;
    text-decoration: none;
}

.button.accordion-button.collapsed {
  font-size: 15px;
  font-weight: 500;
  color:  #067aff;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
  color:#F2C230;
}
.visa-img{
  height:auto;
  width:350px;
  border-radius: 20px;
}
img.img-fluid.c-img {
width:120px;
}
.button-primary-text {
  background-color:  #067aff;
  width: 124px;
  padding: 4px;
  text-align: center;
  border-radius: 20px;
  margin: auto;
  color: #fff;
  font-size: 12px;
}
.services-bg {
  width: 100%;
  height: 50vh;
  background-color:#263173;
  background-image: url('assets/images/page-header-bg.svg');
  }

  /* .body {
    text-align: center;
    padding: 30px;
    background: #f8f4f2;
    font-family: Arial;
  }
   */
  .a-box {
    display: inline-block;
    width: 240px;
    text-align: center;
  }
  
  .img-container {
      height: 230px;
      width: 200px;
      overflow: hidden;
      border-radius: 0px 0px 20px 20px;
      display: inline-block;
  }
  
  .img-container img {
      transform: skew(0deg, -13deg);
      height: 250px;
      margin: -35px 0px 0px -70px;
  }
  
  .inner-skew {
      display: inline-block;
      border-radius: 20px;
      overflow: hidden;
      padding: 0px;
      transform: skew(0deg, 13deg);
      font-size: 0px;
      margin: 30px 0px 0px 0px;
      background: #f2f2f2;;
      height: 250px;
      width: 200px;
  }
  
  .text-container {
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    padding: 120px 20px 20px 20px;
    border-radius: 20px;
    background: #fff;
    margin: -120px 0px 0px 0px;
    line-height: 19px;
    font-size: 14px;
  }
  
  .text-container h3 {
    margin: 20px 0px 10px 0px;
    color: #04bcff;
    font-size: 18px;
  }
  .slider {
    background: white;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    margin-top: 46px;
    margin-bottom: 20px;
  }
  
  .slider::before,
  .slider::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }
  
  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }
  
  .slider::before {
    left: 0;
    top: 0;
  }
  
  .slide-track {
    animation: scroll 20s linear infinite; /* Reduced animation duration to 1 second */
    display: flex;
    width: calc((250px + 40px) * 1);
  }
  
  .slide {
    height: 100px;
    width: 250px;
    margin-right: 40px;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-270px * 7));
    }
  }

 
  @media (min-width: 768px) and (max-width: 1366px) {
    .navbar .dropdown .dropdown:hover>ul {
      left: 70%;
      margin-top: 38px;
    }
  }
  @media (max-width: 767px){
.navbar .dropdown .dropdown:hover>ul {
    left: 16%;
    margin-top: 38px;
}
  }
  @media (max-width: 767px){
  .visa-img {
    width: 234px;
   
}}


/* video */

.main-banner {
  position: relative;
  max-height: 100%;
  overflow: hidden;
  margin-bottom: -7px;
  }
  
  #bg-video {
  min-width: 100%;
  /*min-height: 100vh;*/
  max-width: 100%;
  max-height: 100vh;
  object-fit: cover;
  z-index: -1;
  }
  
  #bg-video::-webkit-media-controls {
  display: none !important;
  }
  
  .video-overlay {
  position: absolute;
  background-color: rgba(31,39,43,0.75);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  }
  
  .main-banner .caption {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  }
  
  .main-banner .caption h6 {
  margin-top: 0px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  letter-spacing: 1px;
  }
  
  .main-banner .caption h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
  letter-spacing: 1px;
  }
  
  .main-banner .caption h2 em {
  font-style: normal;
  color: #f5a425;
  font-weight: 900;
  }
  
  .main-banner .caption p {
  color: #fff;
  font-size: 14px;
  max-width: 570px;
  }
  
  .main-banner .caption .main-button-red {
  margin-top: 30px;
  }
  
  @media screen and (max-width: 767px) {
  
  .main-banner .caption h6 {
  font-weight: 500;
  }
  
  .main-banner .caption h2 {
  font-size: 36px;
  }
  
  }


.card12 {
  position: relative;
 margin-top: 100px;
  height: 540px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  padding: 30px 50px;
  background: #fff;
  cursor: pointer;
 
}



.imgbox {
  position: relative;
  width: 100%;
  height: 100%;
  transform: translateY(-80px);
  text-align: center;
}

.img12 {
  width: 250px;
  height:180px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
}


.content {
  padding: 10px 20px;
  text-align: center;
  transform: translateY(-450px);
  opacity: 0;
  transition: 0.3s;
}

.card12:hover > .content {
  opacity: 1;
  transform: translateY(-180px);
}

.content h2 {
  color: #7f9ead;
}

.img-fluid1 {
  max-width: 82%;
  height: auto;
}


.moving-border {
  width: 400px;
  height: 300px;
  position: relative;
  background: #fff;
  padding: 4px;
}
.moving-border::before,
.moving-border::after {
  content: "";
  position: absolute;
  inset: -0.2rem;
  z-index: -1;
  background: linear-gradient(var(--angle), 
  #0088d1, #0088d1);
  animation: rotate 10s linear infinite;
}
.moving-border::after {
  filter: blur(1px);
}
@keyframes rotate {
  0%     { --angle: 0deg; }
  100%   { --angle: 360deg;
  }
}

.row1 {
  display: flex;
  justify-content: space-between; /* This creates equal spacing between divs */
  gap: 20px; /* Adjust the gap value as needed */
  margin-top: 5rem; /* Adjust the margin as needed */
}

.btn-success1 {
  color: #fff;
  background-color:#263173; 
  border-color: #263173;
  margin:auto;
}

.banner-imgservice {
  height: 200px;
  width: 200px;
  margin-left: 75px;
}


/* box borders*/
.boxborders{
 
  background-color: #fff;
  border-top: 6px solid #0088d1; /* Constant color for top border */
  border-right: 6px solid transparent;
  border-bottom: 6px solid #0088d1; /* Constant color for bottom border */
  border-left: 6px solid transparent;
  box-shadow: 0px 0px 10px rgba(12, 12, 12, 0.3);
  border-inline-width: 6px;
  animation: mymove 5s infinite;
  transition: border-color 0.5s box-shadow 0.5s; ;
}
.boxborders:hover {
  border-color: #0088d1; /* Change border color on hover */
  animation: none; 
}
@keyframes mymove {
  50% { border-color: transparent #263173 transparent #263173; 
  }
}
.myBtn {
    
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color:#f2c230;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}